<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  goodsCategoryRatioList,
  goodsCategoryRatioListSummary,
} from "@/api/decision/saleQueryReport/goodsCategoryRatio"; // 商品畅销分析
export default {
  name: "goodsCategoryRatio",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "商品畅销分析",
        rowKey: "goodsId",
        search: [
          {
            label: "日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "categoryNos", label: "类别编码" },
              { filter: "categoryNames", label: "类别名称" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listSupplier",
            option: {
              filter: "partnerIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择供应商",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "supplier" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "input",
            label: "销量达到",
            hide: () => this.options?.tabColumnType == "sellWell",
            model: 1,
            defaultValue: 1,
            filter: "qty",
            seniorSearch: true,
          },
          {
            type: "input",
            label: "销量低于",
            hide: () => this.options?.tabColumnType == "unsalable",
            model: 1,
            defaultValue: 1,
            filter: "qty",
            seniorSearch: true,
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "sellWell",
        tabsColumns: [
          {
            title: "畅销商品查询",
            type: "sellWell",
            getSummaryApi: goodsCategoryRatioListSummary,
            getListApi: goodsCategoryRatioList,
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/bestselling/ranking/listExport",
              exportName: "畅销商品查询",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
               {
                prop: "brandName",
                label: "品牌",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 120,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "salePrice",
                label: "售价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "purPrice",
                label: "标准进价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "stockQuantity",
                label: "库存数量",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["unitQty", "salePrice", "purPrice", "stockQuantity"],
          },
          {
            title: "滞销商品查询",
            type: "unsalable",
            getSummaryApi: goodsCategoryRatioListSummary,
            getListApi: goodsCategoryRatioList,
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/bestselling/ranking/listExport",
              exportName: "滞销商品查询",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "brandName",
                label: "品牌",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 120,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "salePrice",
                label: "售价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "purPrice",
                label: "标准进价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "stockQuantity",
                label: "库存数量",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["unitQty", "salePrice", "purPrice", "stockQuantity"],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          break;
        default:
          break;
      }
    },
  },
};
</script>
